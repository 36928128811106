import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Carousel from "../Carousel/Carousel";
import GatsbyImage from "gatsby-image";

const StyledCarousel = styled.div`
  .slick-slide {
    ${tw`text-left`}
  }
  .slick-prev,
  .slick-next {
    ${tw`h-12 w-12 bg-transparent border border-solid border-gray-900 rounded-full transform top-0`}
    i {
      ${tw`text-gray-900 text-base`}
    }
    &:hover {
      ${tw`bg-gray-900`}
      i {
        ${tw`text-white`}
      }
    }
  }
  .slick-prev {
    ${tw`left-0`}
  }
  .slick-next {
    ${tw`right-0`}
  }
  .slick-dots {
    ${tw`mt-8`}
    li {
      &.slick-active {
        button {
          ${tw`bg-black`}
        }
      }
    }
    button {
      ${tw`bg-black bg-opacity-25`}
    }
  }
  blockquote {
    ${tw`max-w-4xl`}
  }
  q {
    ${tw`text-2xl text-gray-400 mb-10`}
  }
  footer {
    span {
      ${tw`block text-sm text-gray-400 text-opacity-90 uppercase font-bold tracking-wider mb-10`}
    }
  }
`;

const StyledTestimonial = styled(BackgroundImage)`
  ${tw`flex! items-center`}
  /* min-height: ${({ minHeightMobile }) => {
    return minHeightMobile;
  }}; */
    &:before {
    background-position: ${({ backgroundPositionMobile }) => {
      return backgroundPositionMobile;
    }} !important;
    background-size: ${({ backgroundSizeMobile }) => {
      return backgroundSizeMobile;
    }} !important;
  }
  @media (min-width: 768px) {
    /* min-height: ${({ minHeightDesktop }) => {
      return minHeightDesktop;
    }}; */
    &:before {
      background-position: ${({ backgroundPositionDesktop }) => {
        return backgroundPositionDesktop;
      }} !important;
      background-size: ${({ backgroundSizeDesktop }) => {
        return backgroundSizeDesktop;
      }} !important;
    }
  }
`;

const Testimonial = ({
  className,
  backgroundPositionDesktop = "50% 100%",
  backgroundPositionMobile = "50% 100%",
  backgroundSizeDesktop = "cover",
  backgroundSizeMobile = "cover",
  minHeightDesktop = "585px",
  minHeightMobile = "560px",
}) => {
  const data = useStaticQuery(graphql`
    {
      ctaBannerDesktop: file(
        relativePath: {
          eq: "repeating/testimonials/v2/testimonial-bg-desktop.jpg"
        }
      ) {
        childImageSharp {
          fixed(width: 2880, height: 1152) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      ctaBannerMobile: file(
        relativePath: {
          eq: "repeating/testimonials/v2/testimonial-bg-mobile.jpg"
        }
      ) {
        childImageSharp {
          fixed(width: 750, height: 1152) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      brasaArtLogo: file(
        relativePath: { eq: "repeating/testimonials/v2/business-logo.png" }
      ) {
        publicURL
      }
      testimonialImage: file(
        relativePath: { eq: "repeating/testimonials/v2/testimonial-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 508) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quotev2: file(
        relativePath: { eq: "repeating/testimonials/v2/quote.svg" }
      ) {
        publicURL
      }
    }
  `);

  const backgroundImages = [
    data.ctaBannerDesktop.childImageSharp.fixed,
    {
      ...data.ctaBannerMobile.childImageSharp.fixed,
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <StyledTestimonial
      className={`pt-24 pb-28 ${className}`}
      fixed={backgroundImages}
      minHeightDesktop={minHeightDesktop}
      minHeightMobile={minHeightMobile}
      backgroundSizeDesktop={backgroundSizeDesktop}
      backgroundSizeMobile={backgroundSizeMobile}
      backgroundPositionDesktop={backgroundPositionDesktop}
      backgroundPositionMobile={backgroundPositionMobile}
    >
      <div className="container">
        <StyledCarousel>
          <Carousel>
            <div className="!flex flex-col items-center lg:flex-row gap-x-[80px]">
              <div className="w-full lg:max-w-[508px]">
                <Img
                  fadeIn={false}
                  loading="eager"
                  fluid={data.testimonialImage.childImageSharp.fluid}
                  alt="Mandy Brasa Testimonial"
                  className="w-full"
                />
              </div>
              <div className="w-full max-w-[608px] flex flex-col items-start pt-[40px] lg:pt-0">
                <img
                  className="mb-12 md:mb-10 w-[56px]"
                  src={data.quotev2.publicURL}
                  alt="Quote"
                  width="56"
                />
                <blockquote>
                  <q>
                    Hi Ryan, thank you so much for doing such an excellent job
                    on the sculpture, the fiberglass, and the gloss top coat! I
                    am super happy with the outcome and hope we can work on many
                    more sculptures in the near future. You and your team did
                    amazing work. Please tell everyone that we appreciate their
                    work.
                  </q>
                  <footer>
                    <span>Mandy Brasa | Brasa Art</span>
                    {/* <Img fadeIn={false} loading="eager" fixed={data.carsByKrisLogo.childImageSharp.fixed} alt="Skyline Trailers" /> */}
                  </footer>
                  <img
                    className="mb-0"
                    src={data.brasaArtLogo.publicURL}
                    alt="Brasa Art"
                    width="145"
                  />
                </blockquote>
              </div>
            </div>
          </Carousel>
        </StyledCarousel>
      </div>
    </StyledTestimonial>
  );
};

export default Testimonial;
